<template>
  <div class="home teal-text text-darken-2">

    <div class="d-none d-md-block backgrund-image-placement"
         :style="{'background-image': 'url(' + require('@/assets/img/foto_1.webp') + ')'}"
         style="width: 100%; height: 80vh">
    </div>

    <div class="d-block d-md-none backgrund-image-placement"
         :style="{'background-image': 'url(' + require('@/assets/img/photo_mobile.webp') + ')'}"
         style="width: 100%; height: 50vh">
    </div>

    <div id="menu" class="container position-relative" style="padding: 80px 0px">
      <div class="center-align">
        <button class="button white-text color-azul-claro" @click="$refs.modalReserva.openModal()">
          {{ i18n.buttons.reservar }}
        </button>

        <ModalComponent ref="modalReserva">
          <iframe
              id="restaurante-espinssethotels"
              title="Reservas"
              :src="'https://www.covermanager.com/reserve/module_restaurant/restaurante-espinssethotels/' + i18n.motor_lang"
              frameborder="0"
              height="550"
              width="100%"></iframe>
        </ModalComponent>
      </div>
    </div>

    <div class="color-azul-text container border-radius-15 position-relative">
      <RecursoEstrella style="height: 150px; left: 150px; top: 20px" class="position-absolute d-none d-md-block"/>
      <RecursoCangrejo style="height: 150px; right: 150px; top: 80px" class="position-absolute d-none d-md-block"/>

      <div class="text-center" style="padding: 50px 0.75rem">
        <div class="font-size-30 mb-4">Es Pins</div>

        <div style="max-width: 500px" class="center-align font-size-18">
          {{ i18n.home.el_restaurante }}
        </div>
      </div>
    </div>

    <div class="container" style="padding: 80px 0px">
      <div class="center-align">
        <a href="https://cartas.sethotels.net/es-pins" type="button" target="_blank"
           class="button white-text color-verde text-decoration-none">
          {{ i18n.buttons.carta }}
        </a>
      </div>
    </div>

    <div class="container center-align" style="margin-bottom: 100px; width: 100%">
      <PhotoCarousel
          :images="[
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/es-pins/_MG_49921530.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/es-pins/IMG_7405.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/es-pins/_MG_53781474.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/es-pins/Restaurant_es_pins_06.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/es-pins/_MG_49481566.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/es-pins/Restaurant_es_pins_34.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/es-pins/_MG_4718551.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/es-pins/Restaurant_es_pins_35.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/es-pins/_MG_4570521.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/es-pins/Restaurant_es_pins_36.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/es-pins/Restaurant_es_pins_41.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/es-pins/Restaurant_es_pins_43.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/es-pins/Restaurant_es_pins_48.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/es-pins/Restaurant_es_pins_52.webp',
                    ]"/>
    </div>

    <div class="container mb-md-5 mb-0">
      <FotoTextoDerecha
          :src="require('@/assets/img/foto_8.webp')">
        <RecursoPulpo style="height: 100px; top: 20px" class="position-absolute d-none d-md-block"/>

        <div class="font-size-30 mb-4">{{ i18n.home.santo_tomas }}</div>

        <div class="mb-5 font-size-18">
          {{ i18n.home.santo_tomas_text }}
        </div>
      </FotoTextoDerecha>
    </div>

    <div class="container" style="margin-bottom: 100px">
      <div style="height: 300px"
           class="backgrund-image-placement border-radius-15 overflow-hidden"
           :style="{'background-image': 'url(' + require('@/assets/img/foto_9.webp') + ')'}">
        <div class="h-100 w-100 vertical-align-parent white-text font-size-30"
             style="background-color: rgba(0,0,0,0.2)">
          <div class="vertical-align-element">
            <div class="d-none d-md-block p-3" v-html="i18n.home.nos_une"></div>
            <div class="d-block d-md-none font-size-22 p-3" v-html="i18n.home.nos_une"></div>
            <div style="border-bottom: 2px solid white; width: 150px; margin: auto;"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mb-md-5 mb-0">
      <FotoTextoIzquierda
          class="mb-5"
          src="https://sethotels.ams3.cdn.digitaloceanspaces.com/es-pins/espins_sunset.webp">
        <RecursoConcha style="height: 100px; top: 20px; right: 0px" class="position-absolute d-none d-md-block"/>

        <div class="font-size-30 mb-4">{{ i18n.home.primera_linea }}</div>

        <div class="font-size-18">
          {{ i18n.home.primera_linea_text }}
        </div>
      </FotoTextoIzquierda>

      <FotoTextoDerecha
          :src="require('@/assets/img/foto_11.webp')">
        <RecursoPeces style="height: 70px; top: 20px; transform: rotate(-10deg)"
                      class="position-absolute d-none d-md-block"/>

        <div class="font-size-30 mb-4">{{ i18n.home.km0 }}</div>

        <div class="font-size-18">
          {{ i18n.home.km0_text }}
        </div>
      </FotoTextoDerecha>
    </div>

    <div class="container mb-5">
      <div style="height: 400px"
           class="backgrund-image-placement border-radius-15 overflow-hidden"
           :style="{'background-image': 'url(' + require('@/assets/img/foto_12.webp') + ')'}">
      </div>
    </div>

    <div id="geolocal" class="container mb-5">
      <div class="center-align text-center color-azul-text font-size-22">
        <button
            @click="$refs.modalReserva.openModal()"
            class="button white-text color-azul-claro mb-5">
          {{ i18n.buttons.reservar }}
        </button>

        <div class="mb-3">
          Urbanización Santo Tomas, S/N, 07749
          <br/>
          Santo Tomás, Menorca, España
        </div>

        <div class="mb-3 font-size-14 font-800"
             style="max-width: 30rem">
          {{ i18n.home.parking_text }}
        </div>

        <div>
          Tel. +34 971 37 05 41
        </div>
      </div>
    </div>

    <div class="container">
      <div class="border-radius-15 overflow-hidden">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4327.712816172036!2d4.0422018040651135!3d39.91554560256433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12be1fd8d6ba577b%3A0xc23a0cf75b5a679e!2sEs%20Pins!5e0!3m2!1ses!2ses!4v1692607211507!5m2!1ses!2ses"
            width="100%"
            height="450"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>

    <div class="center-align" style="padding: 90px 0px">
      <EsPinsLogo class="me-3" style="height: 150px"/>
      <a href="https://puntanegramenorca.com/" target="_blank">
        <LogoPuntaNegraCompleto
            style="height: 150px"
        />
      </a>
    </div>
  </div>

</template>

<script>

// @ is an alias to /src

import FotoTextoDerecha from "@/components/FotoTextoDerecha";
import FotoTextoIzquierda from "@/components/FotoTextoIzquierda";
import EsPinsLogo from "@/svg/EsPinsLogo";
import RecursoEstrella from "@/svg/RecursoEstrella";
import RecursoCangrejo from "@/svg/RecursoCangrejo";
import RecursoPulpo from "@/svg/RecursoPulpo";
import RecursoConcha from "@/svg/RecursoConcha";
import RecursoPeces from "@/svg/RecursoPeces";
import PhotoCarousel from "@/components/PhotoCarousel";
import ModalComponent from "@/components/ModalComponent";
import LogoPuntaNegraCompleto from "@/svg/LogoPuntaNegraCompleto.vue";

export default {
  name: 'HomeView',

  components: {
    LogoPuntaNegraCompleto,
    ModalComponent,
    PhotoCarousel,
    RecursoPeces,
    RecursoConcha,
    RecursoPulpo,
    RecursoCangrejo,
    RecursoEstrella,
    EsPinsLogo,
    FotoTextoIzquierda,
    FotoTextoDerecha
  },
}

</script>
